.wrapper {
    & :global .ck-toolbar {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    & :global .ck-content {
        width: 100% !important;
        height: 350px !important;
        border: 1px solid #c4c4c4 !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
}

.invalid {
    & :global .ck-toolbar {
        border-left-color: #ff4842 !important;
        border-right-color: #ff4842 !important;
        border-top-color: #ff4842 !important;
    }

    & :global .ck-content {
        border-left-color: #ff4842 !important;
        border-right-color: #ff4842 !important;
        border-bottom-color: #ff4842 !important;
    }
}

.helper-text {
    margin-top: 5px;
    margin-left: 14px;
}
